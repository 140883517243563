'use client';

import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import Button from '@/components/Button';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { SM_BREAKPOINT } from '@/lib/constants';

const Productivity = () => {
  const containerRef = useRef(null);
  const itemRef = useRef<HTMLElement>(null);
  const [heightPlusMargin, setHeightPlusMargin] = useState<number>(0);
  let numberOfWords: number;
  let tl: GSAPTimeline = gsap.timeline();
  let tlReset: GSAPTimeline = gsap.timeline();

  useEffect(() => {
    const scrollTextMarginBottom = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue('--productivityAnimationMarginBottom'),
    );

    setHeightPlusMargin(-((itemRef.current?.offsetHeight ?? 0) + scrollTextMarginBottom));

    const calcHeight = () => {
      setHeightPlusMargin(-((itemRef.current?.offsetHeight ?? 0) + scrollTextMarginBottom));
    };
    window.addEventListener('resize', calcHeight);

    return () => {
      window.removeEventListener('resize', calcHeight);
    };
  }, []);

  const { contextSafe } = useGSAP(
    () => {
      gsap.matchMediaRefresh();
      const mm = gsap.matchMedia();

      numberOfWords = gsap.utils.toArray('.anim-item').length - 1;
      gsap.set('.anim', { y: heightPlusMargin * numberOfWords });

      mm.add(
        {
          isMobile: `(max-width: ${SM_BREAKPOINT}px)`,
          isDesktop: `(min-width: ${SM_BREAKPOINT + 1}px)`,
        },
        ctx => {
          const { isMobile } = ctx.conditions as gsap.Conditions;

          tl = gsap.timeline({
            repeat: -1,
            paused: isMobile ? false : true,
            defaults: { duration: 1.4, ease: 'elastic.out(0.5,0.25)', delay: 0.1 },
          });

          tlReset = gsap.timeline({
            paused: true,
            defaults: { duration: 1.4, ease: 'elastic.out(0.5,0.25)' },
          });

          tlReset.to('.anim', { y: heightPlusMargin * numberOfWords });

          for (let x = numberOfWords - 1; x > -1; x--) {
            tl.to('.anim', {
              y: heightPlusMargin * x,
            });
          }
        },
      );
    },
    { scope: containerRef, dependencies: [heightPlusMargin] },
  );

  const handleHover = contextSafe(() => {
    tlReset?.kill();
    tl?.restart();
  }) as React.MouseEventHandler;

  const handleMouseOut = contextSafe(() => {
    tl?.kill();
    tlReset?.restart();
  }) as React.MouseEventHandler;

  return (
    <Link
      href={'/develocity/'}
      className="py-section group block justify-center gap-12 border-b-[1px] border-gray-4 bg-gray-1 px-2 transition-colors duration-300 md:gap-14 md:bg-light-background-200 md:px-10 md:hover:bg-gray-1 lg:gap-16"
      ref={containerRef}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseOut}
    >
      <div className="max-w-section flex items-center justify-center">
        <div className="text-h3 flex w-full flex-col items-center justify-center gap-4 overflow-hidden text-gray-1 lg:flex-row">
          <div className="flex cursor-pointer overflow-hidden rounded-full border-2 border-gradle-blue bg-gray-1 px-4 py-3 text-white transition-colors duration-300 md:border-light-background-200 md:bg-white md:px-14 md:py-8 md:text-gray-1 md:group-hover:border-gradle-blue md:group-hover:bg-gray-1 md:group-hover:text-white">
            Improve
            <div className="anim ml-2 flex h-[0] flex-col align-text-bottom md:ml-4">
              <span
                ref={itemRef}
                className="anim-item gradle-gradient-text md:gradle-gradient-text group-hover:gradle-gradient-text mb-[--productivityAnimationMarginBottom] text-gray-1"
              >
                productivity
              </span>
              <span className="anim-item gradle-gradient-text mb-[--productivityAnimationMarginBottom]">
                build speed
              </span>
              <span className="anim-item gradle-gradient-text mb-[--productivityAnimationMarginBottom]">
                observability
              </span>
              <span className="anim-item group-hover:gradle-gradient-text gradle-gradient-text md:text-gray-1">
                productivity
              </span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p className="whitespace-nowrap text-white transition-colors duration-300 md:text-gray-1 md:group-hover:text-white">
              with Develocity
            </p>
            <Button
              outline
              variant="ghost-light"
              size="sm"
              className="self-end border-none bg-white bg-gradient-to-br from-primary to-secondary outline-gray-5 group-hover:outline-none md:invisible md:group-hover:visible md:group-hover:border-none md:group-hover:bg-gradient-to-br"
              roundedFull
            >
              <ArrowRightIcon className="size-5 text-white md:size-5 md:text-black md:group-hover:text-white" />
            </Button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Productivity;
